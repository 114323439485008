import React, { Fragment } from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  Label,
} from "reactstrap";
import { toast } from "react-toastify";
import moment from "moment";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import "../CouponFrom/index.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { BASE_URL } from "../../config";
function FirstBox({ onSelectProduct }) {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/getallproducts`)
      .then(response => setProducts(response.data))
      .catch(error => console.error(error));
  }, []);

  const handleSelectProduct = product => {
    onSelectProduct(product);
    setProducts(prevProducts =>
      prevProducts.filter(p => p.PID !== product.PID)
    );
  };

  return (
    <div className="product-list-container">
      <h2 className="product-list-title">Products</h2>
      <ul className="product-list">
        {products.map(product => (
          <li key={product.PID} onClick={() => onSelectProduct(product)}>
            {product.product_name}
          </li>
        ))}
      </ul>
    </div>
  );
}

function SecondBox({
  selectedProducts,
  onQuantityChange,
  onRemoveProduct,
  couponType,
}) {
  const minValue = couponType === "value" ? 0 : null;
  const minPercentage = couponType === "percentage" ? 0 : null;
  // console.log(selectedProducts, "selected");
  // console.log(minPercentage, "type")
  const containerClass =
    selectedProducts.length > 0
      ? "product-list-container"
      : "product-list-container";

  const handleRemoveProduct = productId => {
    //console.log(productId);
    onRemoveProduct(productId);
    // const updatedSelectedProducts = selectedProducts.filter(
    //   product => product.PID !== productId
    // );
  };
  console.log(selectedProducts, "selecteodjd");
  if (selectedProducts.length > 0) {
    //console.log(selectedProducts, "if");
  } else {
    //console.log(selectedProducts, "esle");
  }
  return (
    // <div className={`product-list-container ${containerClass}`}>
    //   <h2 className="product-list-title">Selected Products</h2>
    //   <div className="product-list-wrapper">
    //     <ul className="product-list">
    //       {selectedProducts.map(product => (
    //         <li key={product.PID}>
    //           {product.product_name}

    //           <Row>
    //             <Col xs={8} lg={couponType === "value" ? 12 : 6}>
    //               <Col xs={4} lg={couponType === "value" ? 12 : 6}>
    //                 <button
    //                   className="remove-button"
    //                   onClick={() => handleRemoveProduct(product.PID)}
    //                   style={{
    //                     position: "absolute",
    //                     top: 0,
    //                     right: 0,
    //                     background: "none",
    //                     border: "none",
    //                     cursor: "pointer",
    //                     zIndex: 9999,
    //                   }}
    //                 >
    //                   <FontAwesomeIcon icon={faTimes} />
    //                 </button>
    //               </Col>
    //               {couponType === "value" ? (
    //                 <input
    //                   type="number"
    //                   value={product.quantity}
    //                   onChange={
    //                     event =>
    //                       onQuantityChange(product._id, event.target.value)
    //                     // {
    //                     //   console.log(event.target.value);
    //                     //   console.log(product._id);
    //                     // }
    //                   }
    //                   min={minValue}
    //                   required
    //                   className="custom-input"
    //                 />
    //               ) : (
    //                 <input
    //                   type="text"
    //                   value={product.quantity}
    //                   onChange={event =>
    //                     onQuantityChange(product._id, event.target.value)
    //                   }
    //                   maxLength="2"
    //                   min={minPercentage}
    //                   required
    //                   className="custom-input"
    //                 />
    //               )}
    //             </Col>
    //           </Row>
    //         </li>
    //       ))}
    //     </ul>
    //   </div>
    // </div>

    <div className={`product-list-container ${containerClass}`}>
      <h2 className="product-list-title">Selected Products</h2>
      <div className="product-list-wrapper">
        <ul className="product-list">
          {selectedProducts.map(product => (
            <li key={product.code ? product.code : product.PID}>
              <div className="product-info">
                <span>
                  {product.name ? product.name : product.product_name}
                </span>
                <button
                  className="remove-button"
                  onClick={() =>
                    onRemoveProduct(
                      product.code ? product.code : product.quantity
                    )
                  }
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
              {couponType === "value" ? (
                <input
                  type="number"
                  value={product.value}
                  onChange={event =>
                    onQuantityChange(product._id, event.target.value)
                  }
                  min={minValue}
                  required
                  className="custom-input"
                />
              ) : (
                <input
                  type="text"
                  value={product.value}
                  onChange={event =>
                    onQuantityChange(product._id, event.target.value)
                  }
                  maxLength="2"
                  min={minPercentage}
                  required
                  className="custom-input"
                />
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
// const today = new Date();
// const currentYear = today.getFullYear();
// const currentMonth = today.getMonth();
// const currentDay = today.getDate();

// const minSelectableDate = new Date(currentYear, currentMonth, currentDay + 1);

// const dd = String(minSelectableDate.getDate()).padStart(2, "0");
// const mm = String(minSelectableDate.getMonth() + 1).padStart(2, "0"); //January is 0!
// const yyyy = minSelectableDate.getFullYear();
// const dtoday = yyyy + "-" + mm + "-" + dd;

// console.log(dtoday);
function CouponForm({ couponData }) {
  const [couponType, setCouponType] = useState("");
  const [couponTypeupdate, setCouponTypeUpdate] = useState("");
  const [showPercentageInput, setShowPercentageInput] = useState(false);
  const [minCartValue, setMinCartValue] = useState("");
  const [discountValue, setDiscountValue] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  // const [couponUsage, setCouponUsage] = useState("");
  const [couponUsage, setCouponUsage] = useState("");
  const [numberOfCoupons, setNumberOfCoupons] = useState("");
  const [usageLimit, setUsageLimit] = useState("");
  const [userType, setUserType] = useState("");
  const [email, setEmail] = useState("");
  const [product, setProduct] = useState("");
  const [multi_coupon_codes, setmulti_coupon_codes] = useState([]);
  // const [selectedProducts, setSelectedProducts] = useState([]);
  const [price, setPrice] = useState(0);
  const [minValue, setMinValue] = useState("");
  const [minPercentage, setMinPercentage] = useState("");
  const [couponSelect, setCouponSelect] = useState("");
  const [coupon, setCoupon] = useState("");
  const [couponPrefix, setCouponPrefix] = useState("");
  const [couponDigits, setCouponDigits] = useState("");
  const [couponCount, setCouponCount] = useState("");
  // const [selectedProducts, setSelectedProducts] = useState([]);
  const [errors, setErrors] = useState({});
  const [startDate, setStartDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [maxdiscountValue, setMaxDiscountValue] = useState("");
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isEditing, setIsEditing] = useState(!!couponData);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/getallproducts`);
        setProducts(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchProducts();
  }, []);

  const handleSelectProduct = product => {
    //console.log(product, "product");
    setSelectedProducts(prevSelectedProducts => [
      ...prevSelectedProducts,
      { ...product, quantity: "" },
    ]);
    setProducts(prevProducts =>
      prevProducts.filter(p => p.PID !== product.PID)
    );
  };
  const [errordate, setErrorDate] = useState({});

  function handleStartDateChange(date) {
    // console.log(date, "startdate");
    setStartDate(date);
  }

  function handleEndDateChange(date) {
    // console.log(date, "enddate");
    setEndDate(date);
  }

  const handleStartTimeChange = time => {
    setStartTime(time);
  };
  const handleEndTimeChange = time => {
    setEndTime(time);
  };
  const handleDiscountValueChange = e => {
    setMaxDiscountValue(e.target.value);
  };
  const handleCouponTypeChange = event => {
    //console.log(event.target.value, "dbdh");
    setCouponType(event.target.value);
    setCouponTypeUpdate(event.target.value);
    setShowPercentageInput(event.target.value === "percentage");
    // if (event && event.target && event.target.value) {
    // }
  };

  const handleRemoveProduct = productId => {
    // onRemoveProduct(productId);
    setSelectedProducts(prevSelectedProducts =>
      prevSelectedProducts.filter(product => product.PID !== productId)
    );
  };
  const handleCouponValueChange = event => {
    // console.log(event.target.value, "dbdh evenet");
    setMinCartValue(event.target.value);
  };
  const handleDiscounValueChange = event => {
    // console.log(event.target.value, "dbdh evenet");
    setDiscountValue(event.target.value);
  };
  // const handleExpiryDateChange = (event) => {
  //   console.log(event, "expirydate");
  //   setExpiryDate(event);
  // };
  const handleCouponUsageChange = event => {
    // console.log(event.target.value, "CouponUsage");
    setCouponUsage(event.target.value);
  };
  const handleUserTypeChange = event => {
    //console.log(event.target.value, "UserType");
    setUserType(event.target.value);
  };
  function handleNumberOfCouponsChange(event) {
    // console.log(event.target.value, "value ");
    // console.log(couponUsage, "suage");
    const value = event.target.value;
    setNumberOfCoupons(couponUsage === "one-time" ? 1 : Number(value));
  }
  const handleEmailChange = event => {
    // console.log(event.target.value, "Email");
    setEmail(event.target.value);
  };
  // const handleProductChange = (event) => {
  //   const productName = event.target.value;
  //   const isSelected = event.target.checked;

  //   if (isSelected) {
  //     setSelectedProducts([...selectedProducts, productName]);
  //   } else {
  //     setSelectedProducts(
  //       selectedProducts.filter((name) => name !== productName)
  //     );
  //   }
  // };

  // function handleSelectProduct(product) {
  //   setSelectedProducts(prevSelectedProducts => [
  //     ...prevSelectedProducts,
  //     { ...product, quantity: "" },
  //   ]);
  // }
  // console.log(couponData.start_date, "startdate");

  // const formatstartTime = extractAndValidateTime(couponData.start_date);
  // const formatendTime = extractAndValidateTime(couponData.end_date);
  // const startTimeParts = couponData.start_time.split(":");
  // const endTimeParts = couponData.end_time.split(":");  // console.log(couponData.start_date, typeof couponData.start_date, "start");
  // console.log(couponData.end_date, typeof couponData.end_date, "enddate");
  // console.log(
  //   couponData.start_time,
  //   typeof couponData.start_time,
  //   "startdtime"
  // );
  // console.log(couponData.end_time, typeof couponData.end_time, "endtime");
  // console.log(startDateformat, "startDateformat");
  // console.log(endDateformat, "endDateformat");
  var start_Date;
  var end_Date;
  var start_Time;
  var end_time;
  if (couponData) {
    const couponstartdate = couponData.startDate;

    const formattedDate = moment(
      couponstartdate,
      "YYYY-MM-DDTHH:mm:ss.SSS"
    ).toDate();

    //console.log(typeof formattedDate, formattedDate, "startdate");
    start_Date = new Date(formattedDate);
    start_Time = {
      hours: start_Date.getHours().toString().padStart(2, "0"),
      minutes: start_Date.getMinutes().toString().padStart(2, "0"),
      seconds: start_Date.getSeconds().toString().padStart(2, "0"),
    };
    // console.log(typeof start_Date, start_Date, "startdate");

    const couponenddate = couponData.endDate;

    const formattedendDate = moment(
      couponenddate,
      "YYYY-MM-DDTHH:mm:ss.SSS"
    ).toDate();
    end_Date = new Date(formattedendDate);
    end_time = {
      hours: end_Date.getHours().toString().padStart(2, "0"),
      minutes: end_Date.getMinutes().toString().padStart(2, "0"),
      seconds: end_Date.getSeconds().toString().padStart(2, "0"),
    };
    // console.log(typeof endDate, endDate, "enddate");
  }

  const initialFormValues = {
    couponType: isEditing ? couponData.coupon.coupon_type : "",
    minCartValue: isEditing ? couponData.coupon.min_cart_value : "",
    discountValue: isEditing ? couponData.coupon.discountValue : "",
    maxdiscountValue: isEditing ? couponData.coupon.maxmaxdiscountValue : "",
    startDate: isEditing ? start_Date : null,
    endDate: isEditing ? end_Date : null,
    couponUsage: isEditing ? couponData.coupon.coupon_usage : "one-time",
    numberOfCoupons: isEditing ? couponData.coupon.max_redemptions : 1,
    userType: isEditing ? couponData.coupon.user_type : "singleuser",
    startTime: isEditing ? start_Time.hours + ":" + start_Time.minutes : null,
    endTime: isEditing ? end_time.hours + ":" + end_time.minutes : null,
    email:
      isEditing && couponData.coupon.user_type === "singleuser"
        ? couponData.user_email
        : "",
    selectedProductsedit: isEditing ? couponData.coupon.PID : [],
    couponSelect: "single",
    coupon: isEditing ? couponData.coupon.coupon_code : "",
    couponPrefix: "",
    couponDigits: 0,
    couponCount: 0,
  };
  //console.log(initialFormValues.coupon, "TYEP");
  function handleQuantityChange(product, quantity) {
    // const discountValue = getDiscountValue(productId);

    setSelectedProducts(prevSelectedProducts =>
      prevSelectedProducts.map(selectedProduct =>
        selectedProduct._id == product
          ? { ...selectedProduct, quantity: Number(quantity) }
          : selectedProduct
      )
    );
  }
  // const handleMinValueChange = (event, index) => {
  //   setSelectedProducts((prevSelected) =>
  //     prevSelected.map((product, i) =>
  //       i === index ? { ...product, minValue: event.target.value } : product
  //     )
  //   );
  // };

  // const handleMinPercentageChange = (event, index) => {
  //   setSelectedProducts((prevSelected) =>
  //     prevSelected.map((product, i) =>
  //       i === index
  //         ? { ...product, minPercentage: event.target.value }
  //         : product
  //     )
  //   );
  // };
  const handleCouponSelectChange = event => {
    // console.log(event.target.value, "elsee");
    setCouponSelect(event.target.value);
  };
  const handleCouponChange = event => {
    setCoupon(event.target.value);
  };
  // const handleUsageLimitChange = (event) => {
  //   setUsageLimit(event);
  // };
  const handlePrefixChange = event => {
    const prefix = event.target.value;
    if (/^[a-zA-Z]+$/.test(prefix)) {
      setCouponPrefix(prefix);
    }
  };

  const handleNumDigitsChange = event => {
    const digits = event.target.value;
    if (digits <= 10) {
      setCouponDigits(digits);
    }
  };

  const handleNumCouponsChange = event => {
    setCouponCount(event.target.value);
  };
  function generateMultipleCoupons(prefix, numDigits, count) {
    const coupons = [];
    for (let i = 0; i < count; i++) {
      const num = (i + 1).toString().padStart(numDigits, "0");
      const coupon = prefix + num;
      coupons.push(coupon);
    }
    const shuffledCoupons = coupons.map(coupon => {
      const shuffledPrefix = prefix
        .split("")
        .sort(() => Math.random() - 0.5)
        .join("");
      const shuffledCoupon = shuffledPrefix + coupon.substr(prefix.length);

      return shuffledCoupon;
    });
    // console.log(shuffledCoupons);
    setmulti_coupon_codes(shuffledCoupons);
  }
  function validate() {
    const newErrors = {};
    if (!startDate) {
      newErrors.startDate = "Start date is required";
    }
    if (!endDate) {
      newErrors.endDate = "End date is required";
    }
    if (new Date(startDate).getTime() > new Date(endDate).getTime()) {
      newErrors.endDate = "End date should be after start date";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }
  const isAnyFieldEmpty = data => {
    for (const key in data) {
      if (!data[key]) {
        return true;
      }
    }
    return false;
  };

  const handleSubmit = event => {
    event.preventDefault();
    // console.log(multi_coupon_codes, "code");
    // console.log(couponType, "type");
    // console.log(expiryDate, "dateexpiry");
    // console.log(couponUsage, "usage");
    // console.log(userType, "type");
    // console.log(numberOfCoupons, "coupons");
    // console.log(selectedProducts, "products");
    // console.log(discountValue, "dicvalue");
    // console.log(coupon, "coupons");
    // console.log(email, "email");
    const startTimeParts = startTime.split(":");
    const endTimeParts = endTime.split(":");

    // const formattedStartDate = startDate
    //   ? new Date(
    //       startDate.getFullYear(),
    //       startDate.getMonth(),
    //       startDate.getDate(),
    //       parseInt(startTimeParts[0]),
    //       parseInt(startTimeParts[1])
    //     ).toUTCString()
    //   : null;

    // const formattedEndDate = endDate
    //   ? new Date(
    //       endDate.getFullYear(),
    //       endDate.getMonth(),
    //       endDate.getDate(),
    //       parseInt(endTimeParts[0]),
    //       parseInt(endTimeParts[1])
    //     ).toUTCString()
    //   : null;
    console.log(startDate, "startdate");
    const formattedStartDate = startDate
      ? moment(startDate)
          .hours(startTimeParts[0])
          .minutes(startTimeParts[1])
          .format("MMMM DD, YYYY HH:mm:ss")
      : null;

    const formattedEndDate = endDate
      ? moment(endDate)
          .hours(endTimeParts[0])
          .minutes(endTimeParts[1])
          .format("MMMM DD, YYYY HH:mm:ss")
      : null;
    //console.log(formattedStartDate, "startdate");
    //console.log(formattedEndDate, "enddate");
    const data = {
      coupon_type: couponType,
      min_cart_value: minCartValue,
      coupon_usage: couponUsage,
      user_type: userType,
      max_redemptions: couponUsage === "one-time" ? 1 : numberOfCoupons,
      multi_coupon_codes: multi_coupon_codes,
      user_email: userType === "alluser" ? "" : email,
      PID: selectedProducts,
      coupon_code: coupon,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      start_time: startTime,
      end_time: endTime,
      discountValue: discountValue,
      maxdiscountValue: maxdiscountValue,
    };
    const newErrors = {};
    if (!email) {
      newErrors.email = "Email is required";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      newErrors.email = "Email is invalid";
    }
    // if (
    //   !couponType ||
    //   !couponValue ||
    //   !couponUsage ||
    //   !userType ||
    //   !numberOfCoupons ||
    //   (!email && userType !== "alluser") ||
    //   !selectedProducts ||
    //   !coupon ||
    //   !startDate ||
    //   !endDate
    // ) {
    //   newErrors.form = "All fields are required";
    //   toast.error(newErrors.form);
    //   console.log(newErrors.form, "eror");
    // }
    // console.log(data, "datahere");
    // if (Object.keys(newErrors).length === 0) {

    // } else {
    //   console.log(data, "dtaaa");
    //   setErrors(newErrors);
    // }

    axios
      .post(`${BASE_URL}/createCoupon`, data)
      .then(response => {
        // console.log(response);
        setCouponType("");
        setMinCartValue("");
        setCouponUsage("");
        setUserType("");
        setNumberOfCoupons("");
        setEmail("");
        setSelectedProducts([]);
        setCoupon("");
        setStartDate("");
        setEndDate("");
        setDiscountValue("");
        setCouponSelect("");
        setErrors({});
        window.location.href = "/coupon-list";
        // alert("Coupon generated successfully!");
        toast.success("Coupon generated successfully!");
      })
      .catch(error => {
        // console.log(error.message, "eorromesage");
        alert(error.message);
      });
  };
  // const formattedStartTime = initialFormValues.startTime.slice(0, 5);
  // const formatEndTime = initialFormValues.endTime.slice(0, 5);
  // const startDate = new Date();
  // startDate.setHours(
  //   parseInt(startTimeParts[0]),
  //   parseInt(startTimeParts[1]),
  //   0,
  //   0
  // );

  // const endDate = new Date();
  // endDate.setHours(parseInt(endTimeParts[0]), parseInt(endTimeParts[1]), 0, 0);
  //console.log(initialFormValues.startTime, "initailstarttime");
  //   typeof initialFormValues.startTime,
  //   typeof formattedStartTime,
  //   "initailstarttime"
  // );
  //console.log(couponTypeupdate, "initailendtime");
  //console.log(isEditing, "eidtinf");

  return (
    <Formik
    //   initialValues={{
    //     couponType: "",
    //     minCartValue: "",
    //     discountValue: "",
    //     startDate: "",
    //     endDate: "",
    //     couponUsage: "",
    //     couponLimit: "",
    //     userType: "",
    //     email: "",
    //     selectedProducts: [],
    //     couponGeneration: "",
    //     prefix: "",
    //     numberOfDigits: "",
    //     numberOfCoupons: "",
    //   }}
    >
      {({ values }) => (
        <Form onSubmit={handleSubmit}>
          <div>
            <h2>Coupon Type</h2>
            <div>
              {isEditing ? (
                <label
                  className={
                    (initialFormValues.couponType || couponTypeupdate) ===
                    "value"
                      ? "green"
                      : "white"
                  }
                >
                  <Field
                    type="radio"
                    name="couponType"
                    value="value"
                    checked={
                      (initialFormValues.couponType || couponTypeupdate) ===
                      "value"
                    }
                    onChange={handleCouponTypeChange}
                    required
                  />
                  Value
                </label>
              ) : (
                <label className={couponType === "value" ? "green" : "white"}>
                  <Field
                    type="radio"
                    name="couponType"
                    value="value"
                    checked={couponType === "value"}
                    onChange={handleCouponTypeChange}
                    required
                  />
                  Value
                </label>
              )}
              {/* <label
                className={
                  isEditing
                    ? initialFormValues.couponType === "value"
                      ? "green"
                      : "white"
                    : couponType === "value"
                    ? "green"
                    : "white"
                }
              >
                <Field
                  type="radio"
                  name="couponType"
                  value="value"
                  checked={couponType === "value"}
                  onChange={handleCouponTypeChange}
                  required
                />
                Value
              </label> */}
            </div>
            <div>
              {isEditing ? (
                <label
                  className={
                    (initialFormValues.couponType || couponTypeupdate) ===
                    "percentage"
                      ? "green"
                      : "white"
                  }
                >
                  <Field
                    type="radio"
                    name="couponType"
                    value="percentage"
                    checked={
                      (initialFormValues.couponType || couponTypeupdate) ===
                      "percentage"
                    }
                    onChange={handleCouponTypeChange}
                    required
                  />
                  Percentage
                </label>
              ) : (
                <label
                  className={couponType === "percentage" ? "green" : "white"}
                >
                  <Field
                    type="radio"
                    name="couponType"
                    value="percentage"
                    checked={couponType === "percentage"}
                    onChange={handleCouponTypeChange}
                    required
                  />
                  Percentage
                </label>
              )}

              {/* <label
                className={
                  isEditing
                    ? initialFormValues.couponType === "percentage"
                      ? "green"
                      : "white"
                    : couponType === "percentage"
                    ? "green"
                    : "white"
                }
              >
                <Field
                  type="radio"
                  name="couponType"
                  value="percentage"
                  checked={
                    isEditing
                      ? initialFormValues.couponType === "percentage"
                      : couponType === "percentage"
                  }
                  onChange={handleCouponTypeChange}
                  required
                />
                Percentage
              </label> */}
            </div>
          </div>
          {isEditing ||
          couponType === "value" ||
          initialFormValues.couponType === "value" ||
          couponTypeupdate === "value" ? (
            <>
              <div>
                <h2>Min Cart Value</h2>
                <Field
                  type="text"
                  name="minCartValue"
                  value={
                    isEditing ? initialFormValues.minCartValue : minCartValue
                  }
                  onChange={handleCouponValueChange}
                  required
                />
              </div>

              <div>
                <h2>Discount Value</h2>
                <Field
                  type="text"
                  name="discountValue"
                  value={
                    isEditing ? initialFormValues.discountValue : discountValue
                  }
                  onChange={handleDiscounValueChange}
                  required
                />
              </div>
              <div>
                <h2>Max Discount Value</h2>
                <Field
                  type="text"
                  name="maxdiscountValue"
                  value={
                    isEditing
                      ? initialFormValues.maxdiscountValue
                      : maxdiscountValue
                  }
                  onChange={handleDiscountValueChange}
                  required
                />
              </div>
            </>
          ) : null}
          {showPercentageInput && (
            <>
              <div>
                <h2>Min Cart Value</h2>
                <Field
                  type="text"
                  name="minCartValue"
                  value={
                    isEditing === "true"
                      ? initialFormValues.minCartValue
                      : minCartValue
                  }
                  onChange={handleCouponValueChange}
                  required
                />
              </div>

              <div>
                <h2>Discount Value</h2>
                <Field
                  type="text"
                  name="discountValue"
                  maxlength="2"
                  value={
                    isEditing === "true"
                      ? initialFormValues.discountValue
                      : discountValue
                  }
                  onChange={handleDiscounValueChange}
                  required
                />
              </div>
              <div>
                <h2>Max Discount Value</h2>
                <Field
                  type="text"
                  name="maxdiscountValue"
                  maxlength="2"
                  value={maxdiscountValue}
                  onChange={handleDiscountValueChange}
                  required
                />
              </div>
            </>
          )}
          <h2>Date Range</h2>
          <div>
            <label>Start Date:</label>
            <DatePicker
              selected={isEditing ? initialFormValues.startDate : startDate}
              onChange={handleStartDateChange}
              minDate={new Date()}
              className="date-picker-textbox"
              required
            />
            <div className="time-field">
              <label htmlFor="start-time-picker">Start Time:</label>
              <div className="time-picker-container">
                <input
                  id="start-time-picker"
                  type="time"
                  value={isEditing ? initialFormValues.startTime : startTime}
                  onChange={e => handleStartTimeChange(e.target.value)}
                  required
                  className="time-picker-input"
                />
              </div>
            </div>
          </div>
          <div>
            <label>End Date:</label>
            <DatePicker
              selected={isEditing ? initialFormValues.endDate : endDate}
              onChange={handleEndDateChange}
              minDate={new Date()}
              className="date-picker-textbox"
              required
            />
            <div className="time-field">
              <label htmlFor="end-time-picker">End Time: </label>
              <div className="time-picker-container">
                <input
                  id="end-time-picker"
                  type="time"
                  value={isEditing ? initialFormValues.endTime : endTime}
                  onChange={e => handleEndTimeChange(e.target.value)}
                  required
                  className="time-picker-input"
                />
              </div>
            </div>
          </div>
          <div>
            <h2>Coupon Usage</h2>
            <div>
              <label>
                <input
                  type="radio"
                  name="couponUsage"
                  value="one-time"
                  checked={
                    isEditing
                      ? initialFormValues.couponUsage === "one-time"
                      : couponUsage === "one-time"
                  }
                  onChange={handleCouponUsageChange}
                  required
                />
                One-time
              </label>
            </div>
            {isEditing
              ? initialFormValues.couponUsage === "one-time"
              : couponUsage === "one-time" && (
                  <div>
                    <h2>Coupon Use Limit</h2>
                    <input
                      type="text"
                      name="numberOfCoupons"
                      value={
                        isEditing
                          ? initialFormValues.couponUsage === "one-time"
                            ? 1
                            : initialFormValues.numberOfCoupons
                          : couponUsage === "one-time"
                          ? 1
                          : numberOfCoupons
                      }
                      onChange={handleNumberOfCouponsChange}
                      required
                    />
                  </div>
                )}
            <div>
              <label>
                <input
                  type="radio"
                  name="couponUsage"
                  value="multi-time"
                  checked={
                    isEditing
                      ? initialFormValues.couponUsage === "multi-time"
                      : couponUsage === "multi-time"
                  }
                  onChange={handleCouponUsageChange}
                  required
                />
                Multi-time
              </label>
            </div>
            {isEditing ||
            couponUsage === "multi-time" ||
            initialFormValues.couponUsage === "multi-time" ? (
              <div>
                <h2>Coupon Use Limit</h2>
                <input
                  type="text"
                  name="numberOfCoupons"
                  value={
                    isEditing
                      ? initialFormValues.numberOfCoupons
                      : numberOfCoupons
                  }
                  onChange={handleNumberOfCouponsChange}
                  required
                />
              </div>
            ) : null}
          </div>

          <div>
            <h2>User Type</h2>
            <div>
              <label>
                <Field
                  type="radio"
                  name="userType"
                  value="singleuser"
                  checked={
                    isEditing
                      ? initialFormValues.userType === "singleuser"
                      : userType === "singleuser"
                  }
                  onChange={handleUserTypeChange}
                  required
                />
                Single User
              </label>
            </div>
            <div>
              <label>
                <Field
                  type="radio"
                  name="userType"
                  value="alluser"
                  checked={
                    isEditing
                      ? initialFormValues.userType === "alluser"
                      : userType === "alluser"
                  }
                  onChange={handleUserTypeChange}
                  required
                />
                All User
              </label>
            </div>
            <div>
              <label>
                <Field
                  type="radio"
                  name="userType"
                  value="newuser"
                  checked={
                    isEditing
                      ? initialFormValues.userType === "newuser"
                      : userType === "newuser"
                  }
                  onChange={handleUserTypeChange}
                  required
                />
                New User
              </label>
            </div>
            {isEditing
              ? initialFormValues.userType === "singleuser"
              : userType === "singleuser" && (
                  <div>
                    <h2>Email</h2>
                    <Field
                      type="email"
                      name="email"
                      value={isEditing ? initialFormValues.email : email}
                      onChange={handleEmailChange}
                      required
                    />
                  </div>
                )}
          </div>
          <div className="boxes-container">
            <div className="box box1">
              <FirstBox onSelectProduct={handleSelectProduct} />
            </div>
            <div className="box box2">
              <SecondBox
                selectedProducts={
                  isEditing
                    ? initialFormValues.selectedProductsedit
                    : selectedProducts
                }
                onQuantityChange={handleQuantityChange}
                onRemoveProduct={handleRemoveProduct}
                couponType={couponType}
                required
              />
            </div>
          </div>
          <div>
            <h2>Coupon Generation</h2>
            <div>
              <label>
                <input
                  type="radio"
                  name="couponSelect"
                  value="single"
                  checked={
                    isEditing
                      ? initialFormValues.couponSelect === "single"
                      : couponSelect === "single"
                  }
                  onChange={handleCouponSelectChange}
                />
                Single Coupon
              </label>
            </div>
            {isEditing ||
            (couponSelect === "single" &&
              initialFormValues.couponSelect === "single") ? (
              <label>
                Coupon Code:
                <input
                  type="text"
                  value={isEditing ? initialFormValues.coupon : coupon}
                  onChange={handleCouponChange}
                  required
                />
              </label>
            ) : null}
            <div>
              <label>
                <input
                  type="radio"
                  value="multiple"
                  checked={couponSelect === "multiple"}
                  onChange={handleCouponSelectChange}
                />
                Multiple Coupons
              </label>
            </div>
            {couponSelect === "multiple" && (
              <>
                <div>
                  <h2>Coupon Prefix</h2>
                  <input
                    type="text"
                    value={couponPrefix}
                    onChange={handlePrefixChange}
                    required
                  />
                </div>
                <div>
                  <h2>Number of Digits</h2>
                  <input
                    type="number"
                    value={couponDigits}
                    onChange={handleNumDigitsChange}
                    required
                  />
                </div>
                <div>
                  <h2>Number of Coupons</h2>
                  <input
                    type="number"
                    value={couponCount}
                    onChange={handleNumCouponsChange}
                    required
                  />
                </div>
                <button
                  type="button"
                  onClick={() =>
                    generateMultipleCoupons(
                      couponPrefix,
                      couponDigits,
                      couponCount
                    )
                  }
                >
                  Generate Coupons
                </button>
              </>
            )}
          </div>
          <Button type="submit" colorScheme="blue">
            Create Coupon
          </Button>
        </Form>
      )}
    </Formik>
  );
}

export default CouponForm;
