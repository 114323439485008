import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  NavItem,
  NavLink,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  Label,
  Alert,
  ModalFooter,
} from "reactstrap";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

import BootstrapTable from "react-bootstrap-table-next";

import images from "assets/images";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import MuiPhoneNumber from "material-ui-phone-number";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import axios from "axios";
const { ExportCSVButton } = CSVExport;
import MiniWidget from "../Dashboard/mini-widget";
import { BASE_URL } from "../../config";

import {
  getUsers,
  addNewUser,
  updateUser,
  deleteUser,
} from "store/contacts/actions";

import { isEmpty, size, map } from "lodash";
import classnames from "classnames";
import axiosInstance from "Axiosinstance";

// {
//   "created_on": "0000-00-00 00:00:00",
//   "id": 215,
//   "primaryEmail": "mandarbongirwar@gmail.com",
//   "firstName": "mandar",
//   "lastName": "bongirwar",
//   "phoneNumber": "9404136055"
// }

class ContactsList extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.state = {
      users: [],
      lfusers: [],
      currentTags: [],
      searchkey: "",
      user: "",
      modal: false,
      isModalOpen: false,
      isAlert: false,
      visible: false,
      visible2: false,
      deletingUserEmail: "",
      userPhoneNo: null,
      userPhoneCountryCode: null,
      reports: [
        // {
        //   icon: "bx bx-copy-alt",
        //   title: "Orders",
        //   value: "1,452",
        //   badgeValue: "+ 0.2%",
        //   color: "success",
        //   desc: "From previous period",
        // },
        // {
        //   icon: "bx bx-archive-in",
        //   title: "Revenue",
        //   value: "$ 28,452",
        //   badgeValue: "+ 0.2%",
        //   color: "success",
        //   desc: "From previous period",
        // },
        // {
        //   icon: "bx bx-purchase-tag-alt",
        //   title: "Average Price",
        //   value: "$ 16.2",
        //   badgeValue: "0%",
        //   color: "warning",
        //   desc: "From previous period",
        // },
      ],
      deleteModal: false,
      contactListColumns: [
        {
          text: "id",
          dataField: "uid",
          sort: true,
          // hidden: true,
          formatter: (cellContent, user) => <>{user.uid}</>,
        },
        // {
        //   dataField: "img",
        //   text: "#",
        //   formatter: (cellContent, user) => (
        //     <>
        //       {!user.img ? (
        //         <div className="avatar-xs">
        //           <span className="avatar-title rounded-circle">
        //             {user.name.charAt(0)}
        //           </span>
        //         </div>
        //       ) : (
        //         <div>
        //           <img
        //             className="rounded-circle avatar-xs"
        //             src={images[user.img]}
        //             alt=""
        //           />
        //         </div>
        //       )}
        //     </>
        //   ),
        // },
        {
          text: "Name",
          dataField: "first_name",
          sort: true,
          formatter: (cellContent, user) => (
            <>
              <h5 className="font-size-14 mb-1">
                <Link to="#" className="text-dark">
                  {user.first_name}
                </Link>
              </h5>
              {/* <p className="text-muted mb-0">{user.designation}</p> */}
            </>
          ),
        },
        {
          dataField: "email",
          text: "Email",
          sort: true,
          formatter: (cellContent, user) => (
            <span className={`${user.isVerify === "0" ? "text-red" : ""}`}>
              {user.email}
            </span>
          ),
        },

        // {
        //   text: "No of Tags",
        //   dataField: "tags",
        //   sort: true,
        //   formatter: (cellContent, user) => (
        //     <>
        //       <h5 className="font-size-14 mb-1">
        //         <Link to="#" className="text-dark">
        //           {user.tags ?  user.tags.length : 0 }
        //         </Link>
        //       </h5>
        //       {/* <p className="text-muted mb-0">{user.designation}</p> */}
        //     </>
        //   ),
        // },

        // {
        //   text: "Tags",
        //   dataField: "tags",
        //   sort: true,
        //   formatter: (cellContent, user) => (
        //     <>
        //       {map(
        //         user.tags,
        //         (tag, index) =>
        //           index < 2 && (
        //             <Link
        //               to="#"
        //               className="badge badge-soft-primary font-size-11 m-1"
        //               key={"_skill_" + user.id + index}
        //             >
        //               {tag}
        //             </Link>
        //           )
        //       )}
        //       {size(user.tags) > 2 && (
        //         <Link
        //           to="#"
        //           className="badge badge-soft-primary font-size-11 m-1"
        //           key={"_skill_" + user.id}
        //         >
        //           {size(user.tags) - 1} + more
        //         </Link>
        //       )}
        //     </>
        //   ),
        // },
        {
          dataField: "phone",
          text: "Phone",
          sort: true,
        },
        {
          dataField: "date",
          text: "Created On",
          sort: true,
          formatter: (cellContent, user) => (
            <>
              <h5 className="font-size-14 mb-1">
                <Link to="#" className="text-dark">
                  {new Date(user.date).toLocaleDateString()}
                </Link>
              </h5>
              {/* <p className="text-muted mb-0">{user.designation}</p> */}
            </>
          ),
        },
        {
          dataField: "tagsCount",
          text: "Tags Count",
          sort: true,
          formatter: (cellContent, user) => (
            <span onClick={() => this.handleOpenModal(user.tags)}>
              {user.tags.length} tags
            </span>
          ),
        },

        {
          dataField: "menu",
          // isDummyField: true,
          editable: false,
          text: "Email Verified",
          formatter: (cellContent, user) => (
            <div className="d-flex gap-3">
              {user?.isVerify === "0" ? (
                <Button
                  onClick={async () => {
                    const { data } = await axiosInstance.post(
                      `/api/resendVerificationEmail/${user.email}`
                    );
                  }}
                  style={{ marginLeft: "0.2rem" }}
                  color="primary"
                  className="font-16 btn-block btn btn-primary"
                >
                  Resend Email
                </Button>
              ) : (
                <h5
                  className="font-size-14 mb-1"
                  style={{ color: "green", fontWeight: "bold" }}
                >
                  Yes
                </h5>
              )}
            </div>
          ),
        },
        {
          dataField: "menu",
          isDummyField: true,
          editable: false,
          text: "View User Details",
          formatter: (cellContent, user) => (
            <div className="d-flex gap-3">
              <Link className="text-success" to="#">
                <i
                  className="mdi mdi-eye font-size-18"
                  id="edittooltip"
                  onClick={() => this.handleUserClick(user)}
                ></i>
              </Link>

              <i
                className="mdi mdi-delete font-size-18 text-danger"
                style={{ cursor: "pointer" }}
                id="edittooltip"
                onClick={() => this.onDeleteUserAlert(user)}
              ></i>
            </div>
          ),
        },
      ],
      lfUsersColumns: [
        {
          text: "id",
          dataField: "id",
          sort: true,
          // hidden: true,
          formatter: (cellContent, user) => <>{user.id}</>,
        },
        {
          text: "Name",
          dataField: "firstName,lastName",
          sort: true,
          formatter: (cellContent, user) => (
            <>
              <h5 className="font-size-14 mb-1">
                <Link to="#" className="text-dark">
                  {user.firstName + " " + user.lastName}
                </Link>
              </h5>
              {/* <p className="text-muted mb-0">{user.designation}</p> */}
            </>
          ),
        },
        {
          text: "Email",
          dataField: "primaryEmail",
          sort: true,
          formatter: (cellContent, user) => (
            <>
              <h5 className="font-size-14 mb-1">
                <Link to="#" className="text-dark">
                  {user.primaryEmail}
                </Link>
              </h5>
              {/* <p className="text-muted mb-0">{user.designation}</p> */}
            </>
          ),
        },
        {
          text: "Phone",
          dataField: "phoneNumber",
          sort: true,
          formatter: (cellContent, user) => (
            <>
              <h5 className="font-size-14 mb-1">
                <Link to="#" className="text-dark">
                  {user.phoneNumber}
                </Link>
              </h5>
              {/* <p className="text-muted mb-0">{user.designation}</p> */}
            </>
          ),
        },

        {
          dataField: "menu",
          isDummyField: true,
          editable: false,
          text: "View User Details",
          formatter: (cellContent, user) => (
            <div className="d-flex gap-3">
              <Link className="text-success" to="#">
                <i
                  className="mdi mdi-eye font-size-18"
                  id="edittooltip"
                  onClick={() => this.handleUserClick(user)}
                ></i>
              </Link>
            </div>
          ),
        },
      ],
      activeTbl: 1,
    };
    this.handleUserClick = this.handleUserClick.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleUserClicks = this.handleUserClicks.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
    this.handleUserDelete = this.handleUserDelete.bind(this);
    this.onDeleteUserAlert = this.onDeleteUserAlert.bind(this);
    this.onDismissAlert = this.onDismissAlert.bind(this);
    this.onDismiss = this.onDismiss.bind(this);
  }
  handleOpenModal = tags => {
    //console.log(tags, "taggsss");
    this.setState({ currentTags: tags, isModalOpen: true });
  };

  // Function to handle closing the modal
  handleCloseModal = () => {
    this.setState({ isModalOpen: false, currentTags: [] });
  };

  componentDidMount() {
    let config = {
      method: "get",
      url: `/api/crm/fetchAllUsers?limit=150`,
      headers: {
        Authorization: JSON.parse(localStorage.getItem("authUser")).token,
      },
    };
    axiosInstance
      .request(config)
      .then(response => {
        // console.log("user data: ", response.data.data)
        this.setState({ users: response.data.data });
        this.setState({
          reports: [
            ...this.state.reports,
            {
              icon: "bx bx-copy-alt",
              title: "All Users",
              value: response.data.data.length.toString(),
              badgeValue: "+ 0.2%",
              color: "success",
              desc: "Total Number of Users",
            },
          ],
        });
      })
      .catch(error => {
        //console.log(error);
      });

    this.fetchDBContent();
    this.fetchInactiveUsers();
    this.fetchLostTags();
    this.fetchLfUsers();
  }

  onDismiss() {
    this.setState({ visible: false });
    this.setState({ visible2: false });
  }
  async handleUserDelete() {
    this.setState(prevState => ({
      isAlert: !prevState.isAlert,
    }));
    const { data } = await axiosInstance.post(
      `/api/deleteUser/${this.state.deletingUserEmail}`
    );
    //console.log("data: ", data);
    if (data?.isError === false && data?.msg === "User Deleted Successfully") {
      window.location.reload();
    } else if (data?.isError === true && data?.msg === "Tags Attached") {
      this.setState(prevState => ({
        visible: true,
      }));
    }
  }
  onDeleteUserAlert(user) {
    this.setState(prevState => ({
      isAlert: !prevState.isAlert,
      deletingUserEmail: user?.email,
    }));
  }
  onDismissAlert() {
    this.setState(prevState => ({
      isAlert: !prevState.isAlert,
      deletingUserEmail: "",
    }));
  }

  fetchLfUsers() {
    let config = {
      method: "get",
      url: `/api/fetchLfUsers`,
      headers: {
        Authorization: JSON.parse(localStorage.getItem("authUser")).token,
      },
    };
    axiosInstance
      .request(config)
      .then(response => {
        //console.log("response--->", response.data.data);
        this.setState({ lfusers: response.data.data });
      })
      .catch(error => {
        //console.log(error);
      });
  }

  fetchDBContent() {
    let config = {
      method: "get",
      url: `/api/fetchRegUsersCount/active`,
      headers: {
        Authorization: JSON.parse(localStorage.getItem("authUser")).token,
      },
    };
    axiosInstance
      .request(config)
      .then(response => {
        //console.log("response.data--->", response.data.data);
        // this.setState({ users: response.data.data });

        this.setState({
          reports: [
            ...this.state.reports,
            {
              icon: "bx bx-copy-alt",
              title: "Active Users",
              value: response.data.data.toString(),
              badgeValue: "+ 0.2%",
              color: "success",
              desc: "Total Number of Users with Activated Tag",
            },
          ],
        });
      })
      .catch(error => {
        //console.log(error);
      });
  }

  fetchInactiveUsers() {
    let config2 = {
      method: "get",
      url: `/api/fetchRegUsersCount/inactive`,
      headers: {
        Authorization: JSON.parse(localStorage.getItem("authUser")).token,
      },
    };
    axiosInstance
      .request(config2)
      .then(response => {
        //console.log("response.data--->", response.data.data);
        // this.setState({ users: response.data.data });

        this.setState({
          reports: [
            ...this.state.reports,
            {
              icon: "bx bx-copy-alt",
              title: "Inactive Users",
              value: response.data.data.toString(),
              badgeValue: "+ 0.2%",
              color: "success",
              desc: "Total Number of Users with Deactivated Tag",
            },
          ],
        });
      })
      .catch(error => {
        //console.log(error);
      });
  }

  fetchLostTags() {
    let config2 = {
      method: "get",
      url: `/api/getAllTagsReportLostCount/lost`,
      headers: {
        Authorization: JSON.parse(localStorage.getItem("authUser")).token,
      },
    };
    axiosInstance
      .request(config2)
      .then(response => {
        //console.log("response.data LOST--->", response.data.totalCount);

        this.setState({
          reports: [
            ...this.state.reports,
            {
              icon: "bx bx-copy-alt",
              title: "Report Lost",
              value: response.data.totalCount.toString(),
              badgeValue: "+ 0.2%",
              color: "success",
              desc: "Total Number of Tags Reported Lost",
            },
          ],
        });
      })
      .catch(error => {
        //console.log(error);
      });
  }

  resetSrch() {
    let config = {
      method: "get",
      url: `/api/crm/fetchAllUsers?limit=150`,
      headers: {
        Authorization: JSON.parse(localStorage.getItem("authUser")).token,
      },
    };
    axiosInstance
      .request(config)
      .then(response => {
        this.setState({ users: response.data.data });
      })
      .catch(error => {
        //console.log(error);
      });
  }

  searchVal(srchKey) {
    let config = {
      method: "get",
      url: `/api/crm/fetchAllUsers?searchValue=${srchKey}`,
      headers: {
        Authorization: JSON.parse(localStorage.getItem("authUser")).token,
      },
    };
    axiosInstance
      .request(config)
      .then(response => {
        //console.log("response.data--->", response.data.data.length);
        this.setState({ users: response.data.data });
      })
      .catch(error => {
        //console.log(error);
      });
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  handleUserClicks = () => {
    this.setState({ user: "", isEdit: false });
    this.toggle();
  };

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { users } = this.state;
    //console.log("users--->", users);
    if (!isEmpty(users) && size(prevProps.users) !== size(users)) {
      //this.setState({ users: {}, isEdit: false });
    }
  }

  changeHandler = e => {
    if (e.target.value.length === 0) {
      this.resetSrch();
    } else {
      this.searchVal(e.target.value);
    }

    this.setState({ [e.target.name]: e.target.value });
  };

  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      // console.log("page--->",page);
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };

  /* Insert,Update Delete data */

  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };

  onClickDelete = users => {
    this.setState({ users: users });
    this.setState({ deleteModal: true });
  };

  handleDeleteUser = () => {
    const { onDeleteUser } = this.props;
    const { users } = this.state;
    if (users.id !== undefined) {
      onDeleteUser(users);
      this.setState({ deleteModal: false });
    }
  };

  handleUserClick = arg => {
    const user = arg;
    let email = user.email != null ? user.email : user.primaryEmail;
    //Redirect to Another Page
    window.open(
      // `https://dashboard.tag8.co.in/#/crm-redirect-login/${email}`, for staging
      `https://dashboard.tag8.in/#/crm-redirect-login/${email}`, // for live
      "_blank" // <- This is what makes it open in a new window.
    );
  };

  render() {
    //meta title
    document.title = "User List";

    // const { users } = this.state
    const { SearchBar } = Search;

    const { users, lfusers } = this.state;

    // console.log("Users--->",users);

    const { isEdit, deleteModal, isModalOpen } = this.state;

    const { onAddNewUser, onUpdateUser } = this.props;
    const user = this.state.user;

    const pageOptions = {
      sizePerPage: 10,
      totalSize: users.length, // replace later with size(users),
      custom: true,
    };

    const pageOptions2 = {
      sizePerPage: 10,
      totalSize: lfusers.length, // replace later with size(users),
      custom: true,
    };

    const defaultSorted = [
      {
        dataField: "uid", // if dataField is not match to any column you defined, it will be ignored.
        order: "desc", // desc or asc
      },
    ];

    //defaultSorted

    const defaultSorted2 = [
      {
        dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
        order: "desc", // desc or asc
      },
    ];

    const selectRow = {
      mode: "checkbox",
    };

    return (
      <React.Fragment>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={this.handleDeleteUser}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />

        <Modal
          isOpen={this.state.isAlert}
          fade={false}
          toggle={this.onDismissAlert}
        >
          <ModalHeader>Are you sure? You want to detele.</ModalHeader>
          <ModalFooter>
            <Button color="primary" onClick={this.handleUserDelete}>
              Delete
            </Button>{" "}
            <Button color="secondary" onClick={this.onDismissAlert}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.visible} fade={false}>
          <ModalBody>
            <Alert color="" isOpen={this.state.visible} toggle={this.onDismiss}>
              This is Active User.
            </Alert>
          </ModalBody>
        </Modal>
        <Modal isOpen={this.state.visible2} fade={false}>
          <ModalBody>
            <Alert
              color=""
              isOpen={this.state.visible2}
              toggle={this.onDismiss}
            >
              <h3>User Already Exist</h3>
            </Alert>
          </ModalBody>
        </Modal>
        <Modal isOpen={isModalOpen} onHide={this.handleCloseModal}>
          <ModalHeader closeButton>Tag Details</ModalHeader>
          <ModalBody>
            <ul>
              {this.state.currentTags.map((tag, index) => (
                <li key={index}>{tag.tag_number}</li>
              ))}
            </ul>
            <Button onClick={this.handleCloseModal}>Close</Button>
          </ModalBody>
        </Modal>

        <div className="page-content">
          {/* DB CArds */}
          <Row>
            <Col xl="12">
              <Row>
                <MiniWidget reports={this.state.reports} />
              </Row>
            </Col>
          </Row>

          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Users" breadcrumbItem="Users List" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    {/* 
                  <h4 className="card-title mb-4">Activities</h4> */}
                    <ul className="nav nav-tabs nav-tabs-custom">
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTbl === 1 ? true : false,
                          })}
                          onClick={() => {
                            this.setState({ activeTbl: 1 });
                          }}
                        >
                          QR Project
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTbl === 2 ? true : false,
                          })}
                          onClick={() => {
                            // this.props.toggleTab("2")
                            this.setState({ activeTbl: 2 });
                          }}
                        >
                          Lost and Found
                        </NavLink>
                      </NavItem>
                    </ul>

                    <div className="mt-4">
                      {this.state.activeTbl === 1 && (
                        <>
                          <PaginationProvider
                            pagination={paginationFactory(pageOptions)}
                            keyField="uid"
                            columns={this.state.contactListColumns}
                            data={users}
                          >
                            {({ paginationProps, paginationTableProps }) => (
                              <ToolkitProvider
                                keyField="uid"
                                columns={this.state.contactListColumns}
                                data={users}
                                search
                                exportCSV
                              >
                                {toolkitprops => (
                                  <React.Fragment>
                                    <Row className="mb-2">
                                      <Col sm="6">
                                        <div className="search-box ms-2 mb-2 d-inline-block">
                                          <div className="position-relative">
                                            {/* <SearchBar
                                                  {...toolkitprops.searchProps}
                                                /> */}
                                            <div className="input-group">
                                              <div className="form-outline">
                                                <input
                                                  type="search"
                                                  id="form1"
                                                  placeholder="Search By User Details"
                                                  className="form-control"
                                                  name="searchkey"
                                                  value={this.state.searchkey}
                                                  onChange={this.changeHandler}
                                                />
                                                {/* <label className="form-label" htmlFor="form1">Search</label> */}
                                              </div>
                                              {/* <button type="button" className="btn btn-primary">
                                                    <i className="fas fa-search"></i>
                                                  </button> */}
                                            </div>

                                            <i className="bx bx-search-alt search-icon" />
                                          </div>
                                        </div>
                                      </Col>
                                      <Col sm="6">
                                        <div className="text-sm-end">
                                          <Button
                                            color="primary"
                                            className="font-16 btn-block btn btn-primary"
                                            onClick={this.handleUserClicks}
                                            style={{ marginRight: "4px" }}
                                          >
                                            <ExportCSVButton
                                              style={{ color: "#fff" }}
                                            >
                                              Create User
                                            </ExportCSVButton>
                                          </Button>
                                          <Button
                                            color="primary"
                                            className="font-16 btn-block btn btn-primary"
                                          >
                                            <ExportCSVButton
                                              style={{ color: "#fff" }}
                                              {...toolkitprops.csvProps}
                                            >
                                              Export CSV!!
                                            </ExportCSVButton>
                                          </Button>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col xl="12">
                                        <div className="table-responsive">
                                          <BootstrapTable
                                            {...toolkitprops.baseProps}
                                            {...paginationTableProps}
                                            // selectRow={selectRow}
                                            defaultSorted={defaultSorted}
                                            classes={
                                              "table align-middle table-nowrap table-hover"
                                            }
                                            bordered={false}
                                            striped={false}
                                            responsive
                                            ref={this.node}
                                          />

                                          <Modal
                                            isOpen={this.state.modal}
                                            className={this.props.className}
                                          >
                                            <ModalHeader
                                              toggle={this.toggle}
                                              tag="h4"
                                            >
                                              {!!isEdit
                                                ? "Edit User"
                                                : "Add User"}
                                            </ModalHeader>
                                            <ModalBody>
                                              <Formik
                                                enableReinitialize={true}
                                                initialValues={{
                                                  firstName:
                                                    (user && user.firstName) ||
                                                    "",
                                                  lastName:
                                                    (user && user.lastName) ||
                                                    "",
                                                  email:
                                                    (user && user.email) || "",
                                                  password:
                                                    (user && user.password) ||
                                                    "",
                                                  phone:
                                                    (user && user.phone) || "",
                                                  countryCode:
                                                    (user &&
                                                      user.countryCode) ||
                                                    "",
                                                }}
                                                validationSchema={Yup.object().shape(
                                                  {
                                                    firstName:
                                                      Yup.string().required(
                                                        "Please Enter Your First Name"
                                                      ),
                                                    lastName:
                                                      Yup.string().required(
                                                        "Please Enter Your Last Name"
                                                      ),
                                                    email:
                                                      Yup.string().required(
                                                        "Please Enter Your Email"
                                                      ),
                                                    password:
                                                      Yup.string().required(
                                                        "Please Enter Your Password"
                                                      ),
                                                    // phone: Yup.number().required(
                                                    //   "Please Enter Your Phone"
                                                    // ),
                                                    // countryCode: Yup.number().required(
                                                    //   "Please Enter Your Country Code"
                                                    // ),
                                                  }
                                                )}
                                                onSubmit={async values => {
                                                  if (isEdit) {
                                                    // const updateUser = {
                                                    //   id: user.id,
                                                    //   name: values.name,
                                                    //   designation: values.designation,
                                                    //   tags: values.tags,
                                                    //   email: values.email,
                                                    //   projects: values.projects,
                                                    // };
                                                    // update user
                                                    // onUpdateUser(updateUser);
                                                  } else {
                                                    // const newUser = {
                                                    //   first_name: values.firstName,
                                                    //   last_name: values.lastName,
                                                    //   email: values.email,
                                                    //   password: values.password,
                                                    //   phone: values.phone,
                                                    //   phonecountrycode: values.countryCode,
                                                    // };
                                                    // save new user
                                                    // console.log("form values: ", values)
                                                    // console.log(this.state.userPhoneNo)
                                                    // console.log(this.state.userPhoneCountryCode)
                                                    values.countryCode =
                                                      this.state.userPhoneCountryCode;
                                                    values.phone =
                                                      this.state.userPhoneNo;
                                                    // console.log("values: ", values)
                                                    const { data } =
                                                      await axiosInstance.post(
                                                        `/api/createUser`,
                                                        values
                                                      );
                                                    // onAddNewUser(newUser);
                                                    //console.log(data);
                                                    if (data?.isError) {
                                                      // console.log("existing");
                                                      this.setState(
                                                        prevState => ({
                                                          visible2: true,
                                                        })
                                                      );
                                                    }
                                                    if (!data?.isError) {
                                                      // console.log("new");
                                                      window.location.reload();
                                                    }
                                                  }
                                                  this.setState({
                                                    selectedUser: null,
                                                  });
                                                  this.toggle();
                                                }}
                                              >
                                                {({
                                                  errors,
                                                  status,
                                                  touched,
                                                }) => (
                                                  <Form>
                                                    <Row>
                                                      <Col className="col-12">
                                                        <div className="mb-3">
                                                          <Label className="form-label">
                                                            First Name
                                                          </Label>
                                                          <Field
                                                            name="firstName"
                                                            type="text"
                                                            className={
                                                              "form-control" +
                                                              (errors.firstName &&
                                                              touched.firstName
                                                                ? " is-invalid"
                                                                : "")
                                                            }
                                                          />
                                                          <ErrorMessage
                                                            name="firstName"
                                                            component="div"
                                                            className="invalid-feedback"
                                                          />
                                                        </div>
                                                        <div className="mb-3">
                                                          <Label className="form-label">
                                                            Last Name
                                                          </Label>
                                                          <Field
                                                            name="lastName"
                                                            type="text"
                                                            className={
                                                              "form-control" +
                                                              (errors.lastName &&
                                                              touched.lastName
                                                                ? " is-invalid"
                                                                : "")
                                                            }
                                                          />
                                                          <ErrorMessage
                                                            name="lastName"
                                                            component="div"
                                                            className="invalid-feedback"
                                                          />
                                                        </div>
                                                        <div className="mb-3">
                                                          <Label className="form-label">
                                                            Email
                                                          </Label>
                                                          <Field
                                                            name="email"
                                                            type="text"
                                                            className={
                                                              "form-control" +
                                                              (errors.email &&
                                                              touched.email
                                                                ? " is-invalid"
                                                                : "")
                                                            }
                                                          />
                                                          <ErrorMessage
                                                            name="email"
                                                            component="div"
                                                            className="invalid-feedback"
                                                          />
                                                        </div>
                                                        <div className="mb-3">
                                                          <Label className="form-label">
                                                            Phone
                                                          </Label>
                                                          {/*
                                                              <Field
                                                                name="phone"
                                                                type="number"
                                                                className={
                                                                  "form-control" +
                                                                  (errors.phone &&
                                                                  touched.phone
                                                                    ? " is-invalid"
                                                                    : "")
                                                                }
                                                              /> */}
                                                          <MuiPhoneNumber
                                                            placeholder="Phone"
                                                            name="phone"
                                                            className={
                                                              "form-control" +
                                                              (errors.phone &&
                                                              touched.phone
                                                                ? " is-invalid"
                                                                : "")
                                                            }
                                                            defaultCountry={
                                                              "in"
                                                            }
                                                            onChange={value => {
                                                              // console.log("value: ", value)
                                                              const code =
                                                                value?.split(
                                                                  " "
                                                                )[0];
                                                              this.setState({
                                                                userPhoneCountryCode:
                                                                  code,
                                                              });
                                                              this.setState({
                                                                userPhoneNo:
                                                                  value
                                                                    ?.slice(
                                                                      code?.length
                                                                    )
                                                                    .trim(),
                                                              });
                                                            }}
                                                          />
                                                          <ErrorMessage
                                                            name="phone"
                                                            component="div"
                                                            className="invalid-feedback"
                                                          />
                                                        </div>
                                                        {/* <div className="mb-3">
                                                              <Label className="form-label">
                                                                Country Code
                                                              </Label>
                                                              <Field
                                                              as=""
                                                                name="countryCode"
                                                                type="select"
                                                                className={
                                                                  "form-control" +
                                                                  (errors.countryCode &&
                                                                  touched.countryCode
                                                                    ? " is-invalid"
                                                                    : "")
                                                                }
                                                              />
                                                              <ErrorMessage
                                                                name="countryCode"
                                                                component="div"
                                                                className="invalid-feedback"
                                                              />
                                                            </div> */}
                                                        <div className="mb-3">
                                                          <Label className="form-label">
                                                            Password
                                                          </Label>
                                                          <Field
                                                            name="password"
                                                            type="password"
                                                            className={
                                                              "form-control" +
                                                              (errors.password &&
                                                              touched.password
                                                                ? " is-invalid"
                                                                : "")
                                                            }
                                                          />
                                                          <ErrorMessage
                                                            name="password"
                                                            component="div"
                                                            className="invalid-feedback"
                                                          />
                                                        </div>
                                                      </Col>
                                                    </Row>
                                                    <Row>
                                                      <Col>
                                                        <div className="text-end">
                                                          <button
                                                            type="submit"
                                                            className="btn btn-success save-user"
                                                          >
                                                            Save
                                                          </button>
                                                        </div>
                                                      </Col>
                                                    </Row>
                                                  </Form>
                                                )}
                                              </Formik>
                                            </ModalBody>
                                          </Modal>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="align-items-md-center mt-30">
                                      <Col className="pagination pagination-rounded justify-content-end mb-2">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </Col>
                                    </Row>
                                  </React.Fragment>
                                )}
                              </ToolkitProvider>
                            )}
                          </PaginationProvider>
                        </>
                      )}
                      {this.state.activeTbl === 2 && (
                        <>
                          <PaginationProvider
                            pagination={paginationFactory(pageOptions2)}
                            keyField="id"
                            columns={this.state.lfUsersColumns}
                            data={lfusers}
                          >
                            {({ paginationProps, paginationTableProps }) => (
                              <ToolkitProvider
                                keyField="id"
                                columns={this.state.lfUsersColumns}
                                data={lfusers}
                                search
                                exportCSV
                              >
                                {toolkitprops => (
                                  <React.Fragment>
                                    <Row className="mb-2">
                                      <Col sm="6">
                                        <div className="search-box ms-2 mb-2 d-inline-block">
                                          <div className="position-relative">
                                            <SearchBar
                                              {...toolkitprops.searchProps}
                                            />
                                            <i className="bx bx-search-alt search-icon" />
                                          </div>
                                        </div>
                                      </Col>
                                      <Col sm="6">
                                        <div className="text-sm-end">
                                          <Button
                                            color="primary"
                                            className="font-16 btn-block btn btn-primary"
                                          >
                                            <ExportCSVButton
                                              style={{ color: "#fff" }}
                                              {...toolkitprops.csvProps}
                                            >
                                              Export CSV!!
                                            </ExportCSVButton>
                                          </Button>
                                        </div>
                                      </Col>
                                      {/* <ExportCSVButton   style={{color: '#fff'}}   { ...toolkitprops.csvProps }>Export CSV!!</ExportCSVButton> */}
                                    </Row>
                                    <Row>
                                      <Col xl="12">
                                        <div className="table-responsive">
                                          <BootstrapTable
                                            {...toolkitprops.baseProps}
                                            {...paginationTableProps}
                                            // selectRow={selectRow}
                                            defaultSorted={defaultSorted2}
                                            classes={
                                              "table align-middle table-nowrap table-hover"
                                            }
                                            bordered={false}
                                            striped={false}
                                            responsive
                                            ref={this.node}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="align-items-md-center mt-30">
                                      <Col className="pagination pagination-rounded justify-content-end mb-2">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </Col>
                                    </Row>
                                  </React.Fragment>
                                )}
                              </ToolkitProvider>
                            )}
                          </PaginationProvider>
                        </>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

ContactsList.propTypes = {
  users: PropTypes.array,
  className: PropTypes.any,
  onGetUsers: PropTypes.func,
  onAddNewUser: PropTypes.func,
  onDeleteUser: PropTypes.func,
  onUpdateUser: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  users: contacts.users,
});

const mapDispatchToProps = dispatch => ({
  onGetUsers: () => dispatch(getUsers()),
  onAddNewUser: user => dispatch(addNewUser(user)),
  onUpdateUser: user => dispatch(updateUser(user)),
  onDeleteUser: user => dispatch(deleteUser(user)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ContactsList));
