module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID: "",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
   //BASE_URL: "http://localhost:3333",
  // For Production
  BASE_URL: "https://crm.tag8.in",
  // For Staging
  //BASE_URL: "https://crm.tag8.co.in",
};
